<template>
  <div>
    <v-subheader class="font-weight-medium" v-if="!hideLabel">
      {{
        hasImported
          ? "Criar modelo com base em assinatura existente"
          : "Já possui uma assinatura de e-mail?"
      }}

      <span v-if="hasImported" class="ml-2">
        <TooltipIcon
          color="info"
          label="A nova assinatura irá ter o HTML definido nesta etapa."
          max-width="300"
          icon="mdi-help-circle-outline"
          size="18"
          :disabled="localLoading || loading"
        />
      </span>
    </v-subheader>
    <!-- ASSINATURA ESCOLHIDA -->
    <v-card outlined v-if="hasImported">
      <v-list-item class="">
        <!-- ESCOLHA A PARTIR DE USUÁRIO -->
        <v-list-item-avatar v-if="selectedUser">
          <!-- IMAGEM DO USUÁRIO -->
          <v-avatar left size="32">
            <img :src="selectedUser.photo || '@/assets/user-default.png'" />
          </v-avatar>
        </v-list-item-avatar>

        <!-- ÍCONE DE HTML SELECIONADO -->
        <v-list-item-avatar v-else>
          <v-avatar left size="32" color="signLighten">
            <v-icon v-text="'mdi-xml'" />
          </v-avatar>
        </v-list-item-avatar>

        <!-- NOME/EMAIL OU TEXTO PADRÃO PARA ASSINATURA HTML -->
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">
            {{ selectedUser ? selectedUser.name : "HTML personalizado" }}
          </v-list-item-title>

          <v-list-item-subtitle v-if="selectedUser">
            {{ selectedUser.email }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="reset" :disabled="localLoading || loading">
            <v-icon v-text="'mdi-trash-can-outline'" />
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>

    <!-- INSERIR HTML -->
    <v-dialog
      v-else
      v-model="dialog"
      width="700"
      :disabled="localLoading || loading"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="color"
          :dark="dark"
          :outlined="outlined"
          :text="text"
          :large="large"
          v-bind="attrs"
          v-on="on"
          :class="`text-none text-body-1 font-weight-medium ${buttonClass}`"
          :disabled="localLoading || loading"
        >
          <v-icon v-if="buttonIcon" left v-html="buttonIcon" class="mr-3" />
          {{ buttonText }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h6"> Importar assinatura </v-card-title>

        <v-card-text class="px-4 mt-3">
          <HtmlEditor
            :html="updatedHtml"
            :disabled="loading"
            min-height="400"
            placeholder="Insira aqui o código HTML da sua assinatura"
            @update="setUpdatedHtml"
          />

          <v-row class="mx-0 my-4 pa-0" align="center" justify="center" dense>
            <v-col>
              <v-divider />
            </v-col>
            <v-col cols="1" class="text-center secondary--text text--lighten-2">
              ou
            </v-col>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <UsersPicker
            :selected="selectedUserIdGoogle"
            :label="'Copiar assinatura de um usuário'"
            :value="'id_google'"
            solo
            background-color="grey lighten-4"
            flat
            @update="selectedUserIdGoogle = $event"
          />
        </v-card-text>

        <v-row class="ma-0 px-0 pt-0 pb-3">
          <v-col :class="`d-flex justify-center`">
            <v-btn
              :loading="loading || localLoading"
              large
              color="accent"
              @click="importSignature"
              dark
              class="text-none text-body-1 font-weight-bold px-6"
            >
              Importar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import UsersPicker from "@/components/forms/field_pickers/UsersPicker.vue";
import HtmlEditor from "@/components/sign/signature/HtmlEditor.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "HtmlImporter",

  components: { HtmlEditor, UsersPicker },

  props: {
    buttonClass: { type: String, default: "" },
    buttonIcon: { type: String, default: "mdi-arrow-expand-up" },
    buttonText: { type: String, default: "Importar assinatura existente" },
    color: { type: String, default: "accent" },
    dark: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    large: { type: Boolean, default: true },
    loading: { type: Boolean, default: true },
    outlined: { type: Boolean, default: true },
    text: { type: Boolean, default: false },
  },

  data() {
    return {
      dialog: false,
      hasImported: false,
      html: "",
      localLoading: false,
      selectedUserIdGoogle: false,
    };
  },

  computed: {
    ...mapGetters(["users"]),

    updatedHtml: {
      get() {
        return this.html;
      },
      set(new_html) {
        this.html = new_html;
      },
    },

    selectedUser() {
      return this.selectedUserIdGoogle
        ? this.users.find((user) => user.id_google == this.selectedUserIdGoogle)
        : null;
    },
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.reset();
      }
    },

    hasImported() {
      this.$emit("update:html", this.updatedHtml);
    },
  },

  methods: {
    ...mapActions(["getUserCurrentSignature"]),

    async importSignature() {
      if (this.selectedUserIdGoogle) {
        await this.getUserSignature();
      } else {
        this.hasImported = true;
        this.dialog = false;
      }
    },

    async getUserSignature() {
      this.localLoading = true;

      if (this.selectedUserIdGoogle) {
        const result = await this.getUserCurrentSignature(
          this.selectedUserIdGoogle
        );

        if (result) {
          this.updatedHtml = result.signature;

          this.hasImported = true;
          this.dialog = false;
        }
      }

      this.localLoading = false;
    },

    setUpdatedHtml(html) {
      this.updatedHtml = html;
    },

    reset() {
      this.updatedHtml = "";
      this.selectedUserIdGoogle = false;
      this.hasImported = false;
    },
  },
};
</script>
